<template>
  <div class="content-responsive">
    <p class="mb-2 section-subtitle">
      {{ $t("text.titulo_selecionar_produtor_grupo") }}
    </p>
    <v-btn
      @click="toggleAddGroupModal()"
      elevation="1"
      color="primary"
      class="px-4"
    >
      {{ btnAddOrEditSelectedProducerOrGroupLabel }}
    </v-btn>
    <dialog-select-producer-or-group
      :key="dialogSelectProducerOrGroupStateKey"
      :state="dialogSelectProducerOrGroupState"
      @producerOrGroupSelected="handleProducerOrGroupSelected"
    />
  </div>
</template>
<script>
import DialogSelectProducerOrGroup from "@/modules/invite/views/components/DialogSelectProducerOrGroup";
import { ProducerType } from "@/modules/core/enums/producer-type.enum";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    DialogSelectProducerOrGroup
  },
  data: () => ({
    dialogSelectProducerOrGroupStateKey: 1,
    dialogSelectProducerOrGroupState: {
      activate: false
    }
  }),
  watch: {
    "dialogSelectProducerOrGroupState.activate": function(newVal) {
      if (!newVal) {
        this.dialogSelectProducerOrGroupStateKey++;
      }
    }
  },
  computed: {
    ...mapGetters({
      hasSelectedProducerOrGroup: "invite/getHasProducerOrGroupSelected"
    }),
    btnAddOrEditSelectedProducerOrGroupLabel() {
      return this.hasSelectedProducerOrGroup
        ? this.$t("text.alterar_produtor_grupo")
        : this.$t("text.adicionar_produtor_grupo");
    }
  },
  methods: {
    ...mapActions("invite", ["setProducerOrGroup"]),
    ...mapActions(["toggleSnackbar"]),
    toggleAddGroupModal() {
      this.dialogSelectProducerOrGroupState.activate = true;
    },
    handleProducerOrGroupSelected(producerOrGroup) {
      this.setProducerOrGroup(producerOrGroup);
      this.toggleSnackbar({
        text: `${
          producerOrGroup.tipo === ProducerType.PRODUCER.id
            ? "Produtor"
            : "Grupo de produtores"
        } selecionado.`,
        type: "success"
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 600px) {
  .content-responsive {
    display: flex;
    flex-direction: column;

    .section-subtitle {
      text-align: center;
    }
  }
}
</style>
