<template>
  <v-stepper-content :step="step" class="d-flex" style="width: 100%">
    <slot></slot>
    <div class="mt-10 d-flex button-content" style="width: 100%">
      <v-btn
        text
        class="px-8 button"
        @click="previusStep()"
        :disabled="previousBtnDisabled"
        width="156"
      >
        {{ previousButtonLabel }}
      </v-btn>

      <optional-tooltip :hide="!showTooltip" :text="tooltipMsg">
        <v-btn
          elevation="1"
          color="primary"
          size="large"
          @click="nextStep(step)"
          class="px-8 button"
          :disabled="nextBtnDisabled"
          :width="this.step === this.max ? 260 : 156"
        >
          {{ nextButtonLabel }}
        </v-btn>
      </optional-tooltip>
    </div>
  </v-stepper-content>
</template>

<script>
import OptionalTooltip from "@/modules/core/views/components/OptionalTooltip";

export default {
  name: "StepperContent",

  components: {
    OptionalTooltip
  },

  props: {
    step: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      required: true
    },
    nextBtnLabel: {
      type: String,
      required: false
    },
    previousBtnLabel: {
      type: String,
      required: false
    },
    nextBtnDisabled: {
      type: Boolean,
      required: false,
      default: () => false
    },
    previousBtnDisabled: {
      type: Boolean,
      required: false,
      default: () => false
    },
    showTooltip: {
      type: Boolean,
      required: false,
      default: () => false
    },
    tooltipMsg: {
      type: String,
      required: false,
      default: () => ""
    }
  },
  computed: {
    previousButtonLabel() {
      if (this.previousBtnLabel) {
        return this.previousBtnLabel;
      }
      return this.step === 1 ? "Cancelar" : "Voltar";
    },
    nextButtonLabel() {
      if (this.nextBtnLabel) {
        return this.nextBtnLabel;
      }
      return this.step === this.max ? "Confirmar e Enviar Convite" : "Avançar";
    }
  },
  methods: {
    nextStep(step) {
      this.$emit("onNext", this.step + 1);
    },
    previusStep() {
      this.$emit("onPrevious", this.step - 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.button-content {
  justify-content: end;
  gap: 8px;
}

@media screen and (max-width: 960px) {
  .button-content {
    justify-content: center;
    flex-direction: column;

    .button {
      width: 100% !important;
    }
  }
}
</style>
