<template>
  <v-dialog
    v-model="state.activate"
    persistent
    origin="center left"
    width="500"
  >
    <v-card>
      <v-card-title class="title-responsive"
        >{{ $t("labels.edit_harvest_certification") }}
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="py-15">
        <v-form ref="form" v-model="formValid">
          <v-row cols="12">
            <v-col>
              <v-select
                v-model="harvestCertificationId"
                dense
                :items="items"
                :rules="harvestCertificationIdRules"
                outlined
                :color="'primary'"
                :label="$t('labels.harvest_certification')"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-layout class="buttons-content">
          <v-btn @click="closeDialog" class="mr-5" color="#FFF">{{
            $t("buttons.cancel")
          }}</v-btn>
          <v-btn
            type="submit"
            :disabled="!formValid"
            color="primary"
            @click="validate"
            >{{ $t("buttons.submit") }}</v-btn
          >
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "DialogEditHarvestCertification",

  props: {
    state: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    formValid: false,
    harvestCertificationId: null,
    harvestCertificationIdRules: [
      value => !!value || "É necessário selecionar uma safra"
    ],
    items: []
  }),

  mounted() {
    this.items = Array.from(
      { length: this.state?.producerOrGroup?.safra_certificacao_limite ?? 0 },
      (x, i) => ({
        text: `${i + 1}ª`,
        value: i + 1
      })
    );
    this.harvestCertificationId = this.state?.productiveUnit?.safra_certificacao;
  },

  methods: {
    ...mapActions("invite", ["setHarvestCertification"]),
    ...mapActions(["toggleSnackbar"]),
    async validate() {
      if (!this.$refs.form.validate()) {
        return;
      }
      try {
        const response = await this.setHarvestCertification({
          id: this.state.productiveUnit.id,
          safra: this.harvestCertificationId
        });
        this.$emit("harvestCertificationUpdated");
        this.state.activate = false;
        this.toggleSnackbar({
          text:
            response.data?.detail ??
            this.$t("text.safra_certificacao_atualizada_sucesso"),
          type: "success"
        });
      } catch (error) {
        this.toggleSnackbar({
          text: error.data?.detail ?? this.$t("text.erro_interno"),
          type: "error"
        });
      }
    },

    closeDialog() {
      this.$refs.form.reset();
      this.state.activate = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/styleguide";

.buttons-content {
  justify-content: flex-end;
}
@media screen and (max-width: 960px) {
  .title-responsive {
    align-items: center;
    justify-content: center;
  }
  .buttons-content {
    justify-content: center;
  }
}
</style>
