<template>
  <div>
    <v-alert class="mt-3" type="info">
      {{ this.$t("text.informativo_safra") }}
    </v-alert>

    <div v-if="exemptSbrhvi">
      <v-alert class="mt-3" type="info">
        {{ $t("text.producer_with_exempt_sbrhvi") }} <br />
      </v-alert>
    </div>

    <div class="my-10">
      <select-producer-or-group />
    </div>

    <selected-producer-or-group />

    <div v-if="hasSelectedProducerOrGroup">
      <productive-units />
    </div>

    <div v-if="hasSelectedProducerOrGroup" class="mb-4 content-responsible">
      <p class="section-subtitle">
        {{ $t("text.selecionar_responsavel_up_programa") }}
      </p>
      <v-btn
        elevation="1"
        color="primary"
        class="px-4"
        @click="addResponsible()"
      >
        {{ $t("text.adicionar_responsavel") }}
      </v-btn>

      <note class="mt-3">{{
        $t("text.informativo_adicionar_responsavel_sbrhvi")
      }}</note>
    </div>

    <div v-if="hasSelectedProducerOrGroup && hasUsers">
      <p class="section-subtitle">
        {{ $t("text.responsaveis_abr") }}
      </p>
      <div>
        <table-responsibles
          :responsibles="abrUsers"
          :headers="abrUsersHeaders"
          @removeResponsible="removeUser($event)"
          @editResponsible="editUser($event)"
        />
      </div>
    </div>

    <dialog-invite-user
      :dialogActivate="dialogInviteUsers"
      @onCloseDialog="onCancelDialogInviteUser"
      @inviteUser="confirmInsertUser"
    />
  </div>
</template>
<script>
import SelectProducerOrGroup from "@/modules/invite/views/components/SelectProducerOrGroup";
import SelectedProducerOrGroup from "@/modules/invite/views/components/SelectedProducerOrGroup";
import DialogSelectProducerOrGroup from "@/modules/invite/views/components/DialogSelectProducerOrGroup";
import ProductiveUnits from "@/modules/invite/views/components/ProductiveUnits";
import Note from "@/modules/core/views/components/Note";
import DialogInviteUser from "@/modules/invite/views/components/DialogInviteUser";
import TableResponsibles from "@/modules/invite/views/components/TableResponsibles";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    SelectProducerOrGroup,
    SelectedProducerOrGroup,
    ProductiveUnits,
    DialogInviteUser,
    TableResponsibles,
    Note
  },
  data: () => ({
    dialogInviteUsers: {
      value: false
    }
  }),
  computed: {
    ...mapGetters({
      abrUsers: "invite/getAbrUsers",
      hasUsers: "invite/getHasAbrUsers",
      exemptSbrhvi: "invite/getExemptSbrhvi",
      hasSelectedProducerOrGroup: "invite/getHasProducerOrGroupSelected"
    }),

    abrUsersHeaders() {
      return [
        { text: "Nome", value: "nome" },
        { text: "CPF", value: "cpf" },
        { text: "E-Mail", value: "email" },
        { text: "Telefone Fixo", value: "telefone" },
        { text: "Celular", value: "celular" },
        { text: "", value: "acao" }
      ];
    }
  },
  methods: {
    ...mapActions("invite", ["addAbrUser", "editAbrUser", "removeAbrUser"]),
    ...mapActions(["toggleSnackbar"]),

    addResponsible() {
      this.dialogInviteUsers.isEdit = false;
      this.dialogInviteUsers.value = true;
      this.dialogInviteUsers.initialData = {};
    },
    async onUpdateUser(initialData) {
      this.dialogInviteUsers.initialData = await initialData;
      this.dialogInviteUsers.isEdit = true;
      this.dialogInviteUsers.value = true;
    },
    onCancelDialogInviteUser() {
      this.dialogInviteUsers.value = false;
      this.dialogInviteUsers.isEdit = false;
    },
    removeUser(user) {
      this.removeAbrUser(user);
    },
    editUser(data) {
      this.onUpdateUser({ ...data });
    },
    confirmInsertUser(payload) {
      const { data, isEdit } = payload;
      this.dialogInviteUsers.value = false;

      if (isEdit) {
        this.editAbrUser(data);
        return;
      }

      this.addAbrUser(data);

      const action = isEdit ? "alterado" : "adicionado";
      this.toggleSnackbar({
        text: `Responsável ${action} com sucesso`,
        type: "success"
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .content-responsible {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }
}
</style>
