var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4"},[_c('p',{staticClass:"section-subtitle"},[_vm._v(" "+_vm._s(_vm.$t( "text.confirmar_unidades_produtivas_pertencentes_produtor_grupo_para_convite" ))+" ")]),(!_vm.productiveUnits.length)?_c('v-row',{staticClass:"d-flex justify-center align-center mt-4 mb-4"},[_c('p',{staticClass:"secondary-text--text"},[_vm._v(" "+_vm._s(_vm.$t("text.nenhum_registro_encontrado", { nome: _vm.$t("labels.unity_productive") }))+" ")])]):_vm._e(),(_vm.productiveUnits.length)?_c('v-data-table',{staticClass:"mb-8 bordered-table",class:{ 'table-delimiter': _vm.useTableDelimiter },attrs:{"hide-default-footer":true,"headers":_vm.headers,"items":_vm.productiveUnits,"items-per-page":100,"no-data-text":_vm.$t('text.nenhum_registro_selecionado_tabelas')},scopedSlots:_vm._u([{key:"item.safra_certificacao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.safra_certificacao ? item.safra_certificacao : _vm.$t("text.field_not_found"))+" ")]}},{key:"item.acao",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-wrap buttons-content"},[_c('v-tooltip',{attrs:{"max-width":"488","top":"","disabled":!_vm.harvestCertificationDisabled ||
              !item.pode_alterar_safra_certificacao,"color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{staticClass:"ma-2",attrs:{"disabled":_vm.harvestCertificationDisabled ||
                    !item.pode_alterar_safra_certificacao,"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.selectHarvestCertification(item)}}},[_vm._v(" "+_vm._s(_vm.$t("labels.edit_harvest_certification"))+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("text.informativo_editar_safra_certificacao_dados_invalidos")))])]),_c('v-tooltip',{attrs:{"max-width":"488","top":"","disabled":_vm.hasSelectedHarvestCertification(item),"color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","small":"","disabled":!_vm.hasSelectedHarvestCertification(item),"color":_vm.isSelected(item) ? 'error' : 'primary'},on:{"click":function($event){return _vm.manageUnit(item)}}},[_vm._v(" "+_vm._s(_vm.isSelected(item) ? _vm.$t("buttons.cancel_selection") : _vm.$t("buttons.confirm_selection_up"))+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("text.informativo_selecionar_up_dados_invalidos")))])])],1)]}},{key:"item.matriculas",fn:function(ref){
                    var item = ref.item;
return [_c('registrations',{attrs:{"values":item.matriculas,"maxLength":3}})]}}],null,true)}):_vm._e(),_c('dialog-edit-harvest-certification',{key:_vm.dialogEditHarvestCertificationStateKey,attrs:{"state":_vm.dialogEditHarvestCertificationState},on:{"harvestCertificationUpdated":_vm.handleHarvestCertificationUpdated}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }