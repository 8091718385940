<template>
  <div>
    <h3 class="mb-4 text-responsible">
      {{ text.titulo_botoes_selecionar_sistemas }}
    </h3>

    <div class="d-flex flex-wrap content-responsible">
      <v-tooltip
        bottom
        v-for="(system, index) in systems"
        :key="system.name"
        color="black"
        :disabled="!hasTooltip(system.name)"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            :class="[
              system.isSelected ? 'item-container-active' : 'item-container',
              { 'disabled-item': system.isDisabled && !system.isSelected }
            ]"
            :disabled="system.disabled"
            @click="selectOption(system, index)"
            style="min-width: 200px;"
          >
            <h2 class="text-center">{{ system.name }}</h2>
          </div>
        </template>
        <div>
          <div class="mt-3">
            Será necessário convidar usuários para os sistemas
            <ul class="mt-1">
              <li>ABR/BCI</li>
              <li>SBRHVI</li>
            </ul>
          </div>
        </div>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import text from "./text";

export default {
  data: () => ({
    text: text,
    systems: [
      {
        name: "ABR/BCI",
        isSelected: false,
        isDisabled: false
      },
      {
        name: "SBRHVI",
        isSelected: false,
        isDisabled: false
      }
    ]
  }),
  methods: {
    selectOption(option, index) {
      if (!option.disabled) {
        if (option.name === "ABR/BCI" && !option.isSelected) {
          this.systems[1].isSelected = true;
          this.systems[1].disabled = true;
        }
        if (option.name === "ABR/BCI" && option.isSelected) {
          this.systems[1].disabled = false;
        }
        this.systems[index].isSelected = !this.systems[index].isSelected;
        this.$emit(
          "onSelectSystems",
          this.systems.filter(system => system.isSelected)
        );
      }
    },
    hasTooltip(systemName) {
      return systemName === "ABR/BCI";
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/stylesheets/styleguide";

.item-container {
  cursor: pointer;
  max-width: 250px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px 16px;
  margin-bottom: 16px;
  margin-right: 16px;
  &:hover {
    border: 1px solid $primary-color-1;
    transition: 0.4s;
    h2 {
      transition: 0.5s;
      color: $primary-color-1;
    }
  }
}

.item-container-active {
  cursor: pointer;
  max-width: 250px;
  background-color: $primary-color-1;
  border: 1px solid $primary-color-1;
  color: white;
  border-radius: 4px;
  padding: 8px 16px;
  margin-bottom: 16px;
  margin-right: 16px;
  h2 {
    transition: 0.5s;
    color: white;
  }
}

.disabled-item {
  cursor: default;
  border: 1px solid #ccc;
  &:hover {
    border: 1px solid #ccc;
    h2 {
      color: #000;
    }
  }
}

@media screen and (max-width: 960px) {
  .text-responsible {
    text-align: center;
  }

  .content-responsible {
    justify-content: center;
    align-items: center;
  }
}
</style>

<style></style>
