<template>
  <div>
    <p class="section-subtitle text-responsible">
      {{ $t("text.titulo_demais_responsaveis_sbrhvi") }}
    </p>
    <v-data-table
      v-if="responsibles.length"
      :hide-default-footer="true"
      :headers="headers"
      :items="responsibles"
      :items-per-page="1000"
      class="bordered-table"
      :no-data-text="$t('text.nenhum_registro_encontrado_tabelas')"
    >
    </v-data-table>

    <div v-else>
      <p class="primary-text--text text-responsible">
        {{ $t("text.titulo_nenhum_responsavel_cadastrado") }}
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "TableOtherResponsibles",

  data: () => ({
    headers: [
      { text: "Nome", value: "nome" },
      { text: "CPF", value: "cpf" },
      { text: "E-mail", value: "email" }
    ]
  }),

  computed: {
    ...mapGetters({ responsibles: "invite/getOtherResponsibles" })
  }
};
</script>
<style></style>
