<template>
  <div>
    <h3 class="mb-4 mt-4 text-responsible">
      {{ $t("text.informativo_revise_informacoes_conclusao") }}
    </h3>

    <div class="card-content pb-5">
      <v-card class="card-custom" color="#f0f0f0">
        <v-card-text>
          <v-row>
            <v-col
              :key="system.name"
              v-for="system in getSelectedSystems"
              md="6"
              sm="12"
              cols="12"
            >
              <h3 class="mb-4 mt-4 text-responsible">
                Programa {{ system.name }}
              </h3>
              <summary-card
                :system="system"
                :users="getUsers(system.name)"
                :grower="newInvite.produtor"
                :productiveUnits="
                  system.name === 'SBRHVI'
                    ? newInvite.produtor.unidades_produtivas
                    : newInvite.abr.unidades_produtivas
                "
                :otherResponsibles="otherResponsibles"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SummaryCard from "./SummaryCard.vue";
export default {
  components: { SummaryCard },
  computed: {
    ...mapGetters({
      newInvite: "invite/getNewInvite",
      selectedSystems: "invite/getSystems",
      hasPreviousSbrhviResponsible: "invite/getHasSbrHviResponsible",
      previousSbrhviResponsibles: "invite/getSbrHviResponsibles",
      otherResponsibles: "invite/getOtherResponsibles",
      getProducerOrGroup: "invite/getProducerOrGroup"
    }),

    getSelectedSystems() {
      const hasSelectedABRBCIAndSBRHVIInFirstStep =
        this.selectedSystems.length === 2;
      if (
        !this.getProducerOrGroup.possui_responsavel_sbrhvi &&
        this.getProducerOrGroup.possui_insencao_sbrhvi &&
        hasSelectedABRBCIAndSBRHVIInFirstStep
      ) {
        return this.selectedSystems.filter(
          s => s.name.toUpperCase() !== "SBRHVI"
        );
      }
      return this.selectedSystems;
    }
  },
  methods: {
    getUsers(systemName) {
      if (systemName === "ABR/BCI") {
        return this.newInvite.abr.convidados;
      }
      if (this.hasPreviousSbrhviResponsible) {
        return this.previousSbrhviResponsibles;
      }
      return [this.newInvite.sbrhvi.convidado];
    }
  }
};
</script>

<style scoped>
.card-custom {
  border-radius: 12px;
}

ul {
  list-style-type: none;
  padding-left: 6px;
}
</style>
